<template>
  <div class="home-container">
    <div class="banner-container">
      <el-carousel trigger="click" height="800px">
        <el-carousel-item>
          <img class="carousel-img" src="~/assets/img/home/banner_01.png" alt="">
          <div class="carousel-one-content">
            <img src="~/assets/img/home/banner_01_text.png" alt="" width="631" height="96">
            <span class="get-solution-btn" @click="getSolution">获取解决方案</span>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="house-wrap">
      <div class="house-main">
        <div class="house-title">我们的智能家居</div>
        <div class="house-houseControl">
          <div class="house-houseControl-text">
            <h5>全屋控制解决方案</h5>
            <h6>HOUSE CONTROL</h6>
            <p>您在回家的路上就可以打开家中的空调、热水器、灯具、音乐等，等回到家中便可从容地享受舒适生活。一木的家电控制系统使用户无论在何时何地都可以进行全屋的家电操控，并且拥有智能安防报警功能，防火防盗，防燃气泄漏。智能、安全保障您的生活。</p>
            <div class="house-houseControl-text-img">
              <img src="~/assets/img/home/houseControl_img2.png" alt="">
              <img src="~/assets/img/home/houseControl_img3.png" alt="">
            </div>
          </div>
          <div class="house-houseControl-img">
            <img src="~/assets/img/home/houseControl_img1.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="industry-wrap">
      <div class="industry-main">
        <div class="industry-title">我们的工业智能</div>
        <div class="industry-detectingSystem">
          <div class="industry-detectingSystem-text">
            <h5>生产状态智能监测系统</h5>
            <h6>DETECTING SYSTEM</h6>
            <p>本系统是基于机器学习的生产状态监测系统，产品适用于三相电机驱动的加工设备，能够对加工过程的设备、制品及人员状态进行实时监测。系统支持为云系统或者工业系统提供数据，为未导入制造执行系统的工厂提供轻量数字化服务，帮助工厂实现加工现场的快速智能化升级。</p>
            <ul>
              <li>
                <img src="~/assets/img/home/lowcost.png" alt="">
              </li>
              <li>
                <img src="~/assets/img/home/flexibility.png" alt="">
              </li>
              <li>
                <img src="~/assets/img/home/feedback.png" alt="">
              </li>
              <li>
                <img src="~/assets/img/home/digitization.png" alt="">
              </li>
            </ul>
          </div>
          <div class="industry-detectingSystem-img">
            <img src="~/assets/img/home/detectingSystem_img1.png" alt="">
          </div>
        </div>
        <div class="industry-automaticallyGrabsSystem">
          <div class="industry-automaticallyGrabsSystem-img">
            <img src="~/assets/img/home/automaticallyGrabsSystem_img1.png" alt="">
          </div>
          <div class="industry-automaticallyGrabsSystem-text">
            <h5>智能上下料系统</h5>
            <h6>AUTOMATICALLY GRABS SYSTEM</h6>
            <p>机器视觉广泛的应用于工业自动化、机器人、质量检测、产品追溯中，俗称工业的眼睛。本系统是利用摄像机、镜头、光源和智能检测算法来人眼在工业中实现缺陷检测、尺寸测量、机器人引导定位和文字识别等功能。</p>
            <ul>
              <li>
                <img src="~/assets/img/home/characteristic1.png" alt="">
                <div>精度高</div>
              </li>
              <li>
                <img src="~/assets/img/home/characteristic2.png" alt="">
                <div>数字化</div>
              </li>
              <li>
                <img src="~/assets/img/home/characteristic3.png" alt="">
                <div>效率高</div>
              </li>
              <li>
                <img src="~/assets/img/home/characteristic4.png" alt="">
                <div>非接触</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperation-wrap">
      <div class="cooperation-main">
        <div class="cooperation-title">合作案例</div>
        <div class="cooperation-state">众多企业选择与一木专业团队合作</div>
        <div class="cooperative-enterprise-list">
          <img src="~/assets/img/home/cooperative_enterprise_01.png" width="289px" height="114px" alt="">
          <img src="~/assets/img/home/cooperative_enterprise_02.png" width="289px" height="114px" alt="">
          <img src="~/assets/img/home/cooperative_enterprise_03.png" width="289px" height="114px" alt="">
          <img src="~/assets/img/home/cooperative_enterprise_04.png" width="289px" height="114px" alt="">
        </div>
      </div>
    </div>
    <div class="exclusive-solution-wrap">
      <div class="exclusive-solution-main">
        <div class="exclusive-solution-title">定制您的专属解决方案</div>
        <div class="get-exclusive-solution-btn" @click="getSolution">立即获取</div>
      </div>
    </div>
    <solution ref="solutionDialog"></solution>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Solution from '../components/Solution.vue'
export default {
  name: 'Home',
  data() {
    return {

    }
  },
  components: {
    Solution
  },
  mounted() {

  },
  methods: {
    getSolution() {
      this.$refs.solutionDialog.showSolutionDialog()
    },
    ...mapMutations([
      
    ]),
    ...mapActions([

    ]),
  },
  computed: {
    ...mapGetters([
      
    ])
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
.banner-container {
  width: 100%;
  height: 800px;
}
.carousel-img {
  width: 100%;
}
.carousel-one-content {
  position: absolute;
  width: 1200px;
  left: 50%;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-50%);
  padding-top: 335px;
}
.get-solution-btn {
  margin-top: 38px;
  width: 169px;
  height: 47px;
  line-height: 47px;
  text-align: center;
  border-radius: 24px;
  background: #197BF0;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  background: url('~assets/img/home/home_btn_bg.png') no-repeat;
  user-select: none;
  &:hover {
    background: url('~assets/img/home/home_btn_hover_bg.png') no-repeat;
  }
}
.house-main {
  padding-top: 68px;
  background: #FFFFFF;
  .house-title {
    height: 38px;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #083157;
    text-align: center;
  }
  .house-houseControl {
    padding: 120px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-around;
    .house-houseControl-text {
      width: 600px;
      padding: 15px;
      >h5 {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
      }
      >h6 {
        margin-top: 30px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F4E6D;
      }
      >p {
        margin-top: 30px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #233E60;
        line-height: 37px;
      }
      .house-houseControl-text-img {
        display: flex;
        justify-content: space-around;
        >img {
          height: 200px;
        }
      }
    }
    .house-houseControl-img {
      width: 600px;
      display: flex;
      align-items: center;
      >img {
        width: 600px;
        height: 450px;
      }
    }
  }
}
.industry-main {
  padding-top: 68px;
  background: #F8FBFE;
  .industry-title {
    height: 38px;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #083157;
    text-align: center;
  }
  .industry-detectingSystem {
    margin-top: 120px;
    padding: 0 120px 120px;
    display: flex;
    justify-content: space-around;
    .industry-detectingSystem-text {
      width: 600px;
      >h5 {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
      }
      >h6 {
        margin-top: 30px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F4E6D;
      }
      >p {
        margin-top: 30px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #233E60;
        line-height: 37px;
      }
      >ul {
        display: flex;
        flex-wrap: wrap;
        >li {
          width: 50%;
          margin-top: 15px;
          display: flex;
          align-items: center;
        }
      }
    }
    .industry-detectingSystem-img {
      width: 600px;
      display: flex;
      align-items: center;
      >img {
        width: 600px;
        height: 450px;
      }
    }
  }
  .industry-automaticallyGrabsSystem {
    padding: 120px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-around;
    .industry-automaticallyGrabsSystem-text {
      width: 600px;
      padding: 15px;
      >h5 {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
      }
      >h6 {
        margin-top: 30px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F4E6D;
      }
      >p {
        margin-top: 30px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #233E60;
        line-height: 37px;
      }
      >ul {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        >li {
          width: 25%;
          text-align: center;
          >div {
            margin-top: 15px;
          }
        }
      }
    }
    .industry-automaticallyGrabsSystem-img {
      width: 600px;
      display: flex;
      >img {
        width: 600px;
        height: 450px;
        align-items: center;
      }
    }
  }
}
.coonsult-item-top {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-top: 10px;
  >h5 {
    @include fh(16px);
    color: #000;
    @extend .ellipsis;
    width: 256px;
    font-weight: 400;
  }
  >time {
    font-size: 12px;
    color: rgba(0, 0, 0, .4);
    margin-top: 12px;
  }
}
.cooperation-wrap {
  height: 376px;
  background: #fff;
}
.cooperation-main {
  width: 1200px;
  margin: auto;
  padding-top: 84px;
}
.cooperation-title {
  @include fh(24px);
  color: #000;
  text-align: center;
}
.cooperation-state {
  font-size: 18px;
  color: rgba(0, 0, 0, .4);
  margin-top: 11px;
  text-align: center;
}
.exclusive-solution-wrap {
  height: 199px;
  background: url('~assets/img/home/exclusive_solution_bg.png') no-repeat;
  background-size: cover;
}
.exclusive-solution-main {
  padding-top: 56px;
  width: 1200px;
  margin: 0 auto;
}
.exclusive-solution-title {
  @include fh(30px);
  text-align: center;
  color: #F6F6F6;
}
.get-exclusive-solution-btn {
  width: 117px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 17px;
  background: #fff;
  color: #0B70F6;
  font-size: 15px;
  margin: 22px auto 0;
  cursor: pointer;
}
.consult-tabs {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  >span {
    width: 89px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    color: #BBBBBB;
    cursor: pointer;
    border-radius: 16px;
    &.active {
      background: #0D7BFC;
      color: #fff;
    }
  }
}
.view-more-btn{
  display: block;
  width: 123px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #BCBCBC;
  cursor: pointer;
  margin: 57px auto 0;
  border-radius: 16px;
  &:hover {
    background: #1C88FF;
    color: #fff;
    border-color: #1C88FF;
  }
}
.cooperative-enterprise-list {
  margin-top: 50px;
  display: flex;
  img {
    margin-right: 11px;
  }
}
</style>
