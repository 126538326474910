<template>
  <el-dialog
    class="solution-dialog"
    title="请填写以下内容，一木工作人员会尽快与您联系"
    :visible.sync="visible"
    width="1082px"
    :append-to-body="true"
    :before-close="closeSolutionDialog">
    <div class="solution-dialog-main">
      <div class="solution-form-list">
        <div class="solution-form-item">
          <span class="label required">姓名</span>
          <input v-model="name" type="text">
        </div>
        <div class="solution-form-item ml10">
          <span class="label required">手机</span>
          <input v-model="phone" type="text">
        </div>
      </div>
      <div class="solution-form-list">
        <div class="solution-form-item">
          <span class="label required">所在地</span>
          <el-cascader
            size="large"
            :options="options"
            v-model="selectedOptions"
            placeholder=""
            @change="handleChange">
          </el-cascader>
        </div>
      </div>
      <div class="solution-form-list">
        <div class="solution-form-item company">
          <span class="label required">公司名称</span>
          <input v-model="companyName" type="text" placeholder="请填写贵公司全称">
        </div>
      </div>
      <div class="solution-form-list">
        <div class="solution-form-item">
          <span class="label required">需求内容</span>
          <textarea v-model="requirement" style="resize: none;" name="" id="" cols="30" rows="7" placeholder="请您填写详细的合作需求"></textarea>
        </div>
      </div>
      <div class="solution-form-list">
        <div class="solution-form-item">
          <span class="label required">验证码</span>
          <input v-model="verifyCode" type="text" placeholder="" style="width: 160px;">
          <div style="margin-top: 14px;">
            <img :src="'data:image/jpeg;base64,' + this.verifyImg" alt="">
            <el-link type="primary" :underline="false" @click="getVerifyCode">看不清楚，换一张</el-link>
          </div>
        </div>
      </div>
      <div class="solution-form-submit-btn" @click="handleSubmit">提交</div>
      <div class="solution-form-tips">
        <p><i class="required">*</i>同一手机号每天最多只能提交一次，请勿重复提交</p>
        <p><i class="required">*</i>为必填项</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
export default {
  data() {
    return {
      visible: false,
      CodeToText: null,
      options: null,
      selectedOptions: [],
      consultActiveTab: 1,
      name: '',
      phone: '',
      companyName: '',
      requirement: '',
      verifyCode: null,
      verifyImg: null,
      verifyKey: null,
    }
  },
  components: {

  },
  mounted() {
    if (process.client) {
      this.CodeToText = require("element-china-area-data").CodeToText
      this.options = require("element-china-area-data").regionData
    }
  },
  methods: {
    showSolutionDialog() {
      this.visible = true
      this.getVerifyCode()
    },
    closeSolutionDialog() {
      this.visible = false
    },
    getVerifyCode() {
      axios.get('/api/sys/verifyImage').then(res => {
        const data = res.data
        if (data.code === 200) {
          this.verifyImg = data.result.image
          this.verifyKey = data.result.verifyKey
        } else {
          this.$message.error(data.message)
        }
      })
    },
    handleChange (value) {
      console.log(this.CodeToText[value[2]])
    },
    handleSubmit() {
      axios.post('/api/contact', {
        name: this.name,
        phone: this.phone,
        companyName: this.companyName,
        requirement: this.requirement,
        province: this.CodeToText[this.selectedOptions[0]],
        city: this.CodeToText[this.selectedOptions[1]],
        town: this.CodeToText[this.selectedOptions[2]],
        verifyCode: this.verifyCode,
        verifyKey: this.verifyKey
      }).then(res => {
        const data = res.data
        if (data.code === 200) {
          this.$message.success(data.message)
          this.visible = false
        } else {
          this.$message.error(data.message)
        }
      })
    },
    ...mapMutations([
      
    ]),
    ...mapActions([

    ]),
  },
  computed: {
    ...mapGetters([
      
    ])
  }
}
</script>

<style scoped lang="scss">
  .solution-dialog {
  /deep/.el-dialog {
    padding-bottom: 25px;
    .el-dialog__header {
      padding: 0;
      padding-top: 49px;
      text-align: center;
      .el-dialog__title {
        color: #000;
        font-size: 30px;
        font-weight: 400;
      }
      .el-dialog__headerbtn {
        display: none;
      }
    }   
    .el-dialog__body {
      padding: 0;
    } 
  }
}
.solution-dialog-main {
  margin-top: 67px;
  padding: 0 48px;
}
.solution-form-list {
  margin-bottom: 38px;
  display: flex;
  align-items: center;
  width: 986px;
}
.solution-form-item {
  display: flex;
  flex-direction: column;
  &.company {
    input {
      width: 986px;
    }
  }
  .label {
    display: inline-block;
    position: relative;
    @include fh(18px);
    color: #000;
    &.required {
      padding-left: 2px;
      &::before {
        content: '*';
        color: #FF0000;
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
  input {
    margin-top: 12px;
    width: 488px;
    height: 55px;
    line-height: 55px;
    vertical-align: middle;
    padding: 0 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    outline: none;
    font-size: 18px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, .2);
    }
  }
  textarea {
    margin-top: 12px;
    width: 986px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    outline: none;
    font-size: 18px;
    height: 203px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, .2);
    }
  }
}
.location-wrap {
  width: 986px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
/deep/ .el-select {
  .el-input__inner {
    outline: none;
    border: 1px solid rgba(0, 0, 0, .2);
    width: 320px;
    height: 55px;
    line-height: 55px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, .2);
    }
  }
}
/deep/ .el-cascader {
  margin-top: 12px;
  .el-input__inner {
    outline: none;
    border: 1px solid rgba(0, 0, 0, .2);
    width: 986px;
    height: 55px;
    font-size: 18px;
    line-height: 55px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, .2);
    }
  }
}
.solution-form-submit-btn {
  margin-top: 42px;
  width:986px;
  height:56px;
  line-height: 56px;
  background:rgba(13,123,252,1);
  box-shadow:0px 0px 24px 0px rgba(13,123,252,0.23);
  border-radius:2px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
}
.solution-form-tips {
  margin-top: 40px;
  >p {
    font-size: 18px;
    @include fh(18px);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .required {
      width: 12px;
      height: 18px;
      color: red;
      font-size: 18px;
    }
  }
}
</style>